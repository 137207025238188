define("ember-new/templates/individual-contract/partials/show-client", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 7,
                    "column": 24
                  },
                  "end": {
                    "line": 9,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-switch", [], ["key", "foreigner"], ["loc", [null, [8, 28], [8, 62]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 10,
                    "column": 24
                  },
                  "end": {
                    "line": 12,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "firstName"], ["loc", [null, [11, 28], [11, 60]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 24
                  },
                  "end": {
                    "line": 15,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "lastName"], ["loc", [null, [14, 28], [14, 59]]]]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 24
                  },
                  "end": {
                    "line": 19,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "pesel"], ["loc", [null, [18, 28], [18, 56]]]]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 24
                  },
                  "end": {
                    "line": 23,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-select-dictionary", [], ["key", "identificationDocumentType", "dictionaryName", "identification_document_type"], ["loc", [null, [22, 28], [22, 136]]]]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 24
                  },
                  "end": {
                    "line": 26,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "identificationDocumentNumber"], ["loc", [null, [25, 28], [25, 79]]]]],
              locals: [],
              templates: []
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 24
                  },
                  "end": {
                    "line": 29,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "email"], ["loc", [null, [28, 28], [28, 56]]]]],
              locals: [],
              templates: []
            };
          })();
          var child7 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 24
                  },
                  "end": {
                    "line": 32,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "telemedic"], ["loc", [null, [31, 28], [31, 60]]]]],
              locals: [],
              templates: []
            };
          })();
          var child8 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 24
                  },
                  "end": {
                    "line": 35,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "phone"], ["loc", [null, [34, 28], [34, 56]]]]],
              locals: [],
              templates: []
            };
          })();
          var child9 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 36,
                    "column": 24
                  },
                  "end": {
                    "line": 38,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "phone2"], ["loc", [null, [37, 28], [37, 57]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 20
                },
                "end": {
                  "line": 39,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(10);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
              morphs[7] = dom.createMorphAt(fragment, 9, 9, contextualElement);
              morphs[8] = dom.createMorphAt(fragment, 10, 10, contextualElement);
              morphs[9] = dom.createMorphAt(fragment, 11, 11, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "d-form-row", [], ["label", "Obcokrajowiec"], 0, null, ["loc", [null, [7, 24], [9, 39]]]], ["block", "d-form-row", [], ["label", "Imię"], 1, null, ["loc", [null, [10, 24], [12, 39]]]], ["block", "d-form-row", [], ["label", "Nazwisko"], 2, null, ["loc", [null, [13, 24], [15, 39]]]], ["block", "d-form-row", [], ["label", "PESEL"], 3, null, ["loc", [null, [17, 24], [19, 39]]]], ["block", "d-form-row", [], ["label", "Rodzaj dokumentu tożsamości"], 4, null, ["loc", [null, [21, 24], [23, 39]]]], ["block", "d-form-row", [], ["label", "Nr i Seria dokumentu tożsamości"], 5, null, ["loc", [null, [24, 24], [26, 39]]]], ["block", "d-form-row", [], ["label", "E-mail"], 6, null, ["loc", [null, [27, 24], [29, 39]]]], ["block", "d-form-row", [], ["label", "Telemedycyna"], 7, null, ["loc", [null, [30, 24], [32, 39]]]], ["block", "d-form-row", [], ["label", "Telefon komórkowy"], 8, null, ["loc", [null, [33, 24], [35, 39]]]], ["block", "d-form-row", [], ["label", "Telefon stacjonarny"], 9, null, ["loc", [null, [36, 24], [38, 39]]]]],
            locals: ["formData", "formContents"],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 16
              },
              "end": {
                "line": 40,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "d-form/individual-contract/client", [], ["model", ["subexpr", "@mut", [["get", "model.client", ["loc", [null, [6, 63], [6, 75]]]]], [], []], "contractId", ["subexpr", "@mut", [["get", "model.id", ["loc", [null, [6, 87], [6, 95]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [6, 101], [6, 105]]]]], [], []]], 0, null, ["loc", [null, [6, 20], [39, 58]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 79
                },
                "end": {
                  "line": 42,
                  "column": 112
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Tak");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 112
                },
                "end": {
                  "line": 42,
                  "column": 123
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Nie");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 16
              },
              "end": {
                "line": 60,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "class", "todo-list");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Obcokrajowiec: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Imię: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Nazwisko: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("PESEL: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Wiek: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" lat(a)");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Rodzaj dokumentu\n                            tożsamości: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Nr i Seria dokumentu\n                            tożsamości: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("E-mail: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Telemedycyna: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Telefon komórkowy: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Telefon stacjonarny: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Zanonimizowany: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(12);
            morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 0]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 0]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [5, 0]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element4, [7, 0]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element4, [9, 0]), 1, 1);
            morphs[5] = dom.createMorphAt(dom.childAt(element4, [11, 0]), 1, 1);
            morphs[6] = dom.createMorphAt(dom.childAt(element4, [13, 0]), 1, 1);
            morphs[7] = dom.createMorphAt(dom.childAt(element4, [15, 0]), 1, 1);
            morphs[8] = dom.createMorphAt(dom.childAt(element4, [17, 0]), 1, 1);
            morphs[9] = dom.createMorphAt(dom.childAt(element4, [19, 0]), 1, 1);
            morphs[10] = dom.createMorphAt(dom.childAt(element4, [21, 0]), 1, 1);
            morphs[11] = dom.createMorphAt(dom.childAt(element4, [23, 0]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.client.foreigner", ["loc", [null, [42, 85], [42, 107]]]]], [], 0, 1, ["loc", [null, [42, 79], [42, 130]]]], ["content", "model.client.firstName", ["loc", [null, [43, 70], [43, 96]]]], ["content", "model.client.lastName", ["loc", [null, [44, 74], [44, 99]]]], ["content", "model.client.pesel", ["loc", [null, [46, 71], [46, 93]]]], ["content", "model.client.age", ["loc", [null, [47, 70], [47, 90]]]], ["content", "model.client.identificationDocumentType.label", ["loc", [null, [50, 49], [50, 98]]]], ["content", "model.client.identificationDocumentNumber", ["loc", [null, [53, 49], [53, 94]]]], ["content", "model.client.email", ["loc", [null, [54, 72], [54, 94]]]], ["content", "model.client.telemedic", ["loc", [null, [55, 78], [55, 104]]]], ["content", "model.client.phone", ["loc", [null, [56, 83], [56, 105]]]], ["content", "model.client.phone2", ["loc", [null, [57, 85], [57, 108]]]], ["inline", "if", [["get", "model.client.isAnonymized", ["loc", [null, [58, 85], [58, 110]]]], "tak", "nie"], [], ["loc", [null, [58, 80], [58, 124]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 12
            },
            "end": {
              "line": 61,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "editState", ["loc", [null, [5, 22], [5, 31]]]]], [], 0, 1, ["loc", [null, [5, 16], [60, 23]]]]],
        locals: ["editState"],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 67,
                    "column": 24
                  },
                  "end": {
                    "line": 69,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-switch", [], ["key", "foreignerSecondClient"], ["loc", [null, [68, 28], [68, 74]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 24
                  },
                  "end": {
                    "line": 72,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "firstNameSecondClient"], ["loc", [null, [71, 28], [71, 72]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 73,
                    "column": 24
                  },
                  "end": {
                    "line": 75,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "lastNameSecondClient"], ["loc", [null, [74, 28], [74, 71]]]]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 24
                  },
                  "end": {
                    "line": 79,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "peselSecondClient"], ["loc", [null, [78, 28], [78, 68]]]]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 81,
                    "column": 24
                  },
                  "end": {
                    "line": 84,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-select-dictionary", [], ["key", "identificationDocumentTypeSecondClient", "dictionaryName", "identification_document_type"], ["loc", [null, [82, 28], [83, 103]]]]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 85,
                    "column": 24
                  },
                  "end": {
                    "line": 87,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "identificationDocumentNumberSecondClient"], ["loc", [null, [86, 28], [86, 91]]]]],
              locals: [],
              templates: []
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 88,
                    "column": 24
                  },
                  "end": {
                    "line": 90,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "emailSecondClient"], ["loc", [null, [89, 28], [89, 68]]]]],
              locals: [],
              templates: []
            };
          })();
          var child7 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 91,
                    "column": 24
                  },
                  "end": {
                    "line": 93,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "phoneSecondClient"], ["loc", [null, [92, 28], [92, 68]]]]],
              locals: [],
              templates: []
            };
          })();
          var child8 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 94,
                    "column": 24
                  },
                  "end": {
                    "line": 96,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "phone2SecondClient"], ["loc", [null, [95, 28], [95, 69]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 20
                },
                "end": {
                  "line": 97,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(9);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
              morphs[7] = dom.createMorphAt(fragment, 9, 9, contextualElement);
              morphs[8] = dom.createMorphAt(fragment, 10, 10, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "d-form-row", [], ["label", "Obcokrajowiec"], 0, null, ["loc", [null, [67, 24], [69, 39]]]], ["block", "d-form-row", [], ["label", "Imię"], 1, null, ["loc", [null, [70, 24], [72, 39]]]], ["block", "d-form-row", [], ["label", "Nazwisko"], 2, null, ["loc", [null, [73, 24], [75, 39]]]], ["block", "d-form-row", [], ["label", "PESEL"], 3, null, ["loc", [null, [77, 24], [79, 39]]]], ["block", "d-form-row", [], ["label", "Rodzaj dokumentu tożsamości"], 4, null, ["loc", [null, [81, 24], [84, 39]]]], ["block", "d-form-row", [], ["label", "Nr i Seria dokumentu tożsamości"], 5, null, ["loc", [null, [85, 24], [87, 39]]]], ["block", "d-form-row", [], ["label", "E-mail"], 6, null, ["loc", [null, [88, 24], [90, 39]]]], ["block", "d-form-row", [], ["label", "Telefon"], 7, null, ["loc", [null, [91, 24], [93, 39]]]], ["block", "d-form-row", [], ["label", "Telefon 2"], 8, null, ["loc", [null, [94, 24], [96, 39]]]]],
            locals: ["formData", "formContents"],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 16
              },
              "end": {
                "line": 98,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "d-form/individual-contract/client-second", [], ["model", ["subexpr", "@mut", [["get", "model.client", ["loc", [null, [65, 70], [65, 82]]]]], [], []], "contractId", ["subexpr", "@mut", [["get", "model.id", ["loc", [null, [65, 94], [65, 102]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [66, 69], [66, 73]]]]], [], []]], 0, null, ["loc", [null, [65, 20], [97, 65]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 100,
                  "column": 79
                },
                "end": {
                  "line": 101,
                  "column": 71
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Tak");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 101,
                  "column": 71
                },
                "end": {
                  "line": 101,
                  "column": 82
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Nie");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 107,
                  "column": 70
                },
                "end": {
                  "line": 108,
                  "column": 101
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" lat(a)");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["content", "model.client.ageSecondClient", ["loc", [null, [108, 62], [108, 94]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 98,
                "column": 16
              },
              "end": {
                "line": 120,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "class", "todo-list");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Obcokrajowiec: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Imię: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Nazwisko: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("PESEL: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Wiek: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Rodzaj dokumentu\n                            tożsamości: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Nr i Seria dokumentu\n                            tożsamości: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("E-mail: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Telefon: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Telefon 2: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 0]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [3, 0]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [5, 0]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element3, [7, 0]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element3, [9, 0]), 1, 1);
            morphs[5] = dom.createMorphAt(dom.childAt(element3, [11, 0]), 1, 1);
            morphs[6] = dom.createMorphAt(dom.childAt(element3, [13, 0]), 1, 1);
            morphs[7] = dom.createMorphAt(dom.childAt(element3, [15, 0]), 1, 1);
            morphs[8] = dom.createMorphAt(dom.childAt(element3, [17, 0]), 1, 1);
            morphs[9] = dom.createMorphAt(dom.childAt(element3, [19, 0]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.client.foreignerSecondClient", ["loc", [null, [101, 32], [101, 66]]]]], [], 0, 1, ["loc", [null, [100, 79], [101, 89]]]], ["content", "model.client.firstNameSecondClient", ["loc", [null, [102, 70], [102, 108]]]], ["content", "model.client.lastNameSecondClient", ["loc", [null, [103, 74], [103, 111]]]], ["content", "model.client.peselSecondClient", ["loc", [null, [106, 71], [106, 105]]]], ["block", "if", [["get", "model.client.ageSecondClient", ["loc", [null, [108, 32], [108, 60]]]]], [], 2, null, ["loc", [null, [107, 70], [108, 108]]]], ["content", "model.client.identificationDocumentTypeSecondClient.label", ["loc", [null, [111, 49], [111, 110]]]], ["content", "model.client.identificationDocumentNumberSecondClient", ["loc", [null, [114, 49], [114, 106]]]], ["content", "model.client.emailSecondClient", ["loc", [null, [115, 72], [115, 106]]]], ["content", "model.client.phoneSecondClient", ["loc", [null, [116, 73], [116, 107]]]], ["content", "model.client.phone2SecondClient", ["loc", [null, [117, 75], [117, 110]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 63,
              "column": 12
            },
            "end": {
              "line": 121,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "editState", ["loc", [null, [64, 22], [64, 31]]]]], [], 0, 1, ["loc", [null, [64, 16], [120, 23]]]]],
        locals: ["editState"],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 129,
                    "column": 24
                  },
                  "end": {
                    "line": 131,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "street"], ["loc", [null, [130, 28], [130, 57]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 132,
                    "column": 24
                  },
                  "end": {
                    "line": 134,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "streetNo"], ["loc", [null, [133, 28], [133, 59]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 135,
                    "column": 24
                  },
                  "end": {
                    "line": 137,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "aptNo"], ["loc", [null, [136, 28], [136, 56]]]]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 138,
                    "column": 24
                  },
                  "end": {
                    "line": 140,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "postalCode"], ["loc", [null, [139, 28], [139, 61]]]]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 141,
                    "column": 24
                  },
                  "end": {
                    "line": 143,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "postalCity"], ["loc", [null, [142, 28], [142, 61]]]]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 144,
                    "column": 24
                  },
                  "end": {
                    "line": 146,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "city"], ["loc", [null, [145, 28], [145, 55]]]]],
              locals: [],
              templates: []
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 147,
                    "column": 24
                  },
                  "end": {
                    "line": 149,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "plotNumber"], ["loc", [null, [148, 28], [148, 61]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 128,
                  "column": 20
                },
                "end": {
                  "line": 150,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "d-form-row", [], ["label", "Ulica"], 0, null, ["loc", [null, [129, 24], [131, 39]]]], ["block", "d-form-row", [], ["label", "Numer domu"], 1, null, ["loc", [null, [132, 24], [134, 39]]]], ["block", "d-form-row", [], ["label", "Numer lokalu"], 2, null, ["loc", [null, [135, 24], [137, 39]]]], ["block", "d-form-row", [], ["label", "Kod pocztowy"], 3, null, ["loc", [null, [138, 24], [140, 39]]]], ["block", "d-form-row", [], ["label", "Poczta"], 4, null, ["loc", [null, [141, 24], [143, 39]]]], ["block", "d-form-row", [], ["label", "Miejscowość"], 5, null, ["loc", [null, [144, 24], [146, 39]]]], ["block", "d-form-row", [], ["label", "Nr działki"], 6, null, ["loc", [null, [147, 24], [149, 39]]]]],
            locals: ["formData", "formContents"],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 127,
                "column": 16
              },
              "end": {
                "line": 151,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "d-form/individual-contract/base-address", [], ["model", ["subexpr", "@mut", [["get", "baseAddress", ["loc", [null, [128, 69], [128, 80]]]]], [], []], "contractId", ["subexpr", "@mut", [["get", "model.id", ["loc", [null, [128, 92], [128, 100]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [128, 106], [128, 110]]]]], [], []]], 0, null, ["loc", [null, [128, 20], [150, 64]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 151,
                "column": 16
              },
              "end": {
                "line": 161,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "class", "todo-list");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Ulica: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Numer domu: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Numer lokalu: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Kod pocztowy: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Poczta: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Miejscowość: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Nr działki: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 0]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 0]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [5, 0]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element2, [7, 0]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element2, [9, 0]), 1, 1);
            morphs[5] = dom.createMorphAt(dom.childAt(element2, [11, 0]), 1, 1);
            morphs[6] = dom.createMorphAt(dom.childAt(element2, [13, 0]), 1, 1);
            return morphs;
          },
          statements: [["content", "model.client.baseAddress.street", ["loc", [null, [153, 71], [153, 106]]]], ["content", "model.client.baseAddress.streetNo", ["loc", [null, [154, 76], [154, 113]]]], ["content", "model.client.baseAddress.aptNo", ["loc", [null, [155, 78], [155, 112]]]], ["content", "model.client.baseAddress.postalCode", ["loc", [null, [156, 78], [156, 117]]]], ["content", "model.client.baseAddress.postalCity", ["loc", [null, [157, 72], [157, 111]]]], ["content", "model.client.baseAddress.city", ["loc", [null, [158, 77], [158, 110]]]], ["content", "model.client.baseAddress.plotNumber", ["loc", [null, [159, 76], [159, 115]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 126,
              "column": 12
            },
            "end": {
              "line": 162,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "editState", ["loc", [null, [127, 22], [127, 31]]]]], [], 0, 1, ["loc", [null, [127, 16], [161, 23]]]]],
        locals: ["editState"],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 178,
                    "column": 24
                  },
                  "end": {
                    "line": 180,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "street"], ["loc", [null, [179, 28], [179, 57]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 181,
                    "column": 24
                  },
                  "end": {
                    "line": 183,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "streetNo"], ["loc", [null, [182, 28], [182, 59]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 184,
                    "column": 24
                  },
                  "end": {
                    "line": 186,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "aptNo"], ["loc", [null, [185, 28], [185, 56]]]]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 187,
                    "column": 24
                  },
                  "end": {
                    "line": 189,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "postalCode"], ["loc", [null, [188, 28], [188, 61]]]]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 190,
                    "column": 24
                  },
                  "end": {
                    "line": 192,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "postalCity"], ["loc", [null, [191, 28], [191, 61]]]]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 193,
                    "column": 24
                  },
                  "end": {
                    "line": 195,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "city"], ["loc", [null, [194, 28], [194, 55]]]]],
              locals: [],
              templates: []
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 196,
                    "column": 24
                  },
                  "end": {
                    "line": 198,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "d-field-text", [], ["key", "plotNumber"], ["loc", [null, [197, 28], [197, 61]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 174,
                  "column": 20
                },
                "end": {
                  "line": 199,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "d-form-row", [], ["label", "Ulica"], 0, null, ["loc", [null, [178, 24], [180, 39]]]], ["block", "d-form-row", [], ["label", "Numer domu"], 1, null, ["loc", [null, [181, 24], [183, 39]]]], ["block", "d-form-row", [], ["label", "Numer lokalu"], 2, null, ["loc", [null, [184, 24], [186, 39]]]], ["block", "d-form-row", [], ["label", "Kod pocztowy"], 3, null, ["loc", [null, [187, 24], [189, 39]]]], ["block", "d-form-row", [], ["label", "Poczta"], 4, null, ["loc", [null, [190, 24], [192, 39]]]], ["block", "d-form-row", [], ["label", "Miejscowość"], 5, null, ["loc", [null, [193, 24], [195, 39]]]], ["block", "d-form-row", [], ["label", "Nr działki"], 6, null, ["loc", [null, [196, 24], [198, 39]]]]],
            locals: ["formData", "formContents"],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 168,
                "column": 16
              },
              "end": {
                "line": 200,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "height: 33px;");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "btn btn-success pull-right");
            var el3 = dom.createTextNode("\n                            Kopiuj dane z adresu głównego\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["element", "action", ["copyAddressFromMainAddress"], [], ["loc", [null, [170, 67], [170, 106]]]], ["block", "d-form/individual-contract/additional-address", [], ["model", ["subexpr", "@mut", [["get", "additionalAddress", ["loc", [null, [174, 75], [174, 92]]]]], [], []], "contractId", ["subexpr", "@mut", [["get", "model.id", ["loc", [null, [175, 80], [175, 88]]]]], [], []], "currentFormData", ["subexpr", "@mut", [["get", "addressForm", ["loc", [null, [176, 85], [176, 96]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [177, 74], [177, 78]]]]], [], []]], 0, null, ["loc", [null, [174, 20], [199, 70]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 200,
                "column": 16
              },
              "end": {
                "line": 220,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            dom.setAttribute(el1, "class", "todo-list");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Ulica: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Numer domu: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Numer lokalu: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Kod pocztowy: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Poczta: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Miejscowość: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("li");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "text");
            var el4 = dom.createElement("strong");
            var el5 = dom.createTextNode("Nr działki: ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 0]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 0]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 0]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [7, 0]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [9, 0]), 1, 1);
            morphs[5] = dom.createMorphAt(dom.childAt(element0, [11, 0]), 1, 1);
            morphs[6] = dom.createMorphAt(dom.childAt(element0, [13, 0]), 1, 1);
            return morphs;
          },
          statements: [["content", "model.client.additionalAddress.street", ["loc", [null, [202, 71], [202, 112]]]], ["content", "model.client.additionalAddress.streetNo", ["loc", [null, [204, 76], [204, 119]]]], ["content", "model.client.additionalAddress.aptNo", ["loc", [null, [207, 76], [207, 116]]]], ["content", "model.client.additionalAddress.postalCode", ["loc", [null, [210, 76], [210, 121]]]], ["content", "model.client.additionalAddress.postalCity", ["loc", [null, [213, 70], [213, 115]]]], ["content", "model.client.additionalAddress.city", ["loc", [null, [216, 75], [216, 114]]]], ["content", "model.client.additionalAddress.plotNumber", ["loc", [null, [218, 76], [218, 121]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 167,
              "column": 12
            },
            "end": {
              "line": 221,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "editState", ["loc", [null, [168, 22], [168, 31]]]]], [], 0, 1, ["loc", [null, [168, 16], [220, 23]]]]],
        locals: ["editState"],
        templates: [child0, child1]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 229,
                      "column": 28
                    },
                    "end": {
                      "line": 231,
                      "column": 28
                    }
                  },
                  "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "d-field-text", [], ["key", "identificationDocumentTypePikaText", "disabled", true], ["loc", [null, [230, 32], [230, 103]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.2",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 232,
                      "column": 28
                    },
                    "end": {
                      "line": 235,
                      "column": 28
                    }
                  },
                  "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "d-field-select-dictionary", [], ["key", "identificationDocumentType", "dictionaryName", "identification_document_type"], ["loc", [null, [233, 32], [234, 107]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 227,
                    "column": 24
                  },
                  "end": {
                    "line": 236,
                    "column": 24
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "d-form-row", [], ["label", "Rodzaj dokumentu tożsamości - PIKA"], 0, null, ["loc", [null, [229, 28], [231, 43]]]], ["block", "d-form-row", [], ["label", "Rodzaj dokumentu tożsamości"], 1, null, ["loc", [null, [232, 28], [235, 43]]]]],
              locals: ["formData", "formContents"],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 226,
                  "column": 20
                },
                "end": {
                  "line": 237,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "d-form/individual-contract/pika-client", [], ["model", ["subexpr", "@mut", [["get", "model.client", ["loc", [null, [227, 72], [227, 84]]]]], [], []], "contractId", ["subexpr", "@mut", [["get", "model.id", ["loc", [null, [227, 96], [227, 104]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [228, 71], [228, 75]]]]], [], []]], 0, null, ["loc", [null, [227, 24], [236, 67]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 239,
                    "column": 28
                  },
                  "end": {
                    "line": 246,
                    "column": 28
                  }
                },
                "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "text");
                var el3 = dom.createElement("strong");
                var el4 = dom.createTextNode("Rodzaj dokumentu tożsamości - PIKA: ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "text");
                var el3 = dom.createElement("strong");
                var el4 = dom.createTextNode("Rodzaj dokumentu tożsamości: ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 2, 2);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 0]), 2, 2);
                return morphs;
              },
              statements: [["content", "model.client.identificationDocumentTypePikaText", ["loc", [null, [241, 36], [241, 87]]]], ["content", "model.client.identificationDocumentType.label", ["loc", [null, [244, 36], [244, 85]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 237,
                  "column": 20
                },
                "end": {
                  "line": 248,
                  "column": 20
                }
              },
              "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "todo-list");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "model.client.identificationDocumentTypePikaText", ["loc", [null, [239, 34], [239, 81]]]]], [], 0, null, ["loc", [null, [239, 28], [246, 35]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.2",
            "loc": {
              "source": null,
              "start": {
                "line": 224,
                "column": 16
              },
              "end": {
                "line": 249,
                "column": 16
              }
            },
            "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "editState", ["loc", [null, [226, 26], [226, 35]]]]], [], 0, 1, ["loc", [null, [226, 20], [248, 27]]]]],
          locals: ["editState"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.2",
          "loc": {
            "source": null,
            "start": {
              "line": 223,
              "column": 12
            },
            "end": {
              "line": 250,
              "column": 12
            }
          },
          "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "box-for-form", [], ["title", "Braki słownikowe PIKA", "isAllowedEdit", ["subexpr", "@mut", [["get", "model.credentials.editClient", ["loc", [null, [225, 46], [225, 74]]]]], [], []]], 0, null, ["loc", [null, [224, 16], [249, 33]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.3.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 254,
            "column": 10
          }
        },
        "moduleName": "ember-new/templates/individual-contract/partials/show-client.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "content");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-4");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-4");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-4");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0, 1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(element6, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(element7, 1, 1);
        morphs[4] = dom.createMorphAt(element7, 3, 3);
        morphs[5] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "box-for-form", [], ["title", "Dane klienta", "isAllowedEdit", ["subexpr", "@mut", [["get", "isEditable", ["loc", [null, [4, 63], [4, 73]]]]], [], []]], 0, null, ["loc", [null, [4, 12], [61, 29]]]], ["block", "box-for-form", [], ["title", "Dane drugiego klienta", "isAllowedEdit", ["subexpr", "@mut", [["get", "isEditable", ["loc", [null, [63, 72], [63, 82]]]]], [], []], "isAllowedDelete", ["subexpr", "@mut", [["get", "isAllowedToDelete", ["loc", [null, [63, 99], [63, 116]]]]], [], []]], 1, null, ["loc", [null, [63, 12], [121, 29]]]], ["block", "box-for-form", [], ["title", "Adres główny", "isAllowedEdit", ["subexpr", "@mut", [["get", "isEditable", ["loc", [null, [126, 63], [126, 73]]]]], [], []]], 2, null, ["loc", [null, [126, 12], [162, 29]]]], ["block", "box-for-form", [], ["title", "Adres korespondencyjny", "isAllowedEdit", ["subexpr", "@mut", [["get", "isEditable", ["loc", [null, [167, 73], [167, 83]]]]], [], []]], 3, null, ["loc", [null, [167, 12], [221, 29]]]], ["block", "if", [["get", "model.client.identificationDocumentTypePikaText", ["loc", [null, [223, 18], [223, 65]]]]], [], 4, null, ["loc", [null, [223, 12], [250, 19]]]], ["content", "outlet", ["loc", [null, [254, 0], [254, 10]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});