define('ember-new/models/address', ['exports', 'ember-new/models/abstract', 'ember-data'], function (exports, _emberNewModelsAbstract, _emberData) {

    var Address = _emberNewModelsAbstract['default'].extend({
        street: _emberData['default'].attr('string'),
        streetNo: _emberData['default'].attr('string'),
        aptNo: _emberData['default'].attr('string'),
        postalCode: _emberData['default'].attr('string'),
        postalCity: _emberData['default'].attr('string'),
        city: _emberData['default'].attr('string'),
        district: _emberData['default'].belongsTo('DistrictDictionaryEntry', { inverse: null }),
        districtUuid: _emberData['default'].attr('string'),
        county: _emberData['default'].belongsTo('CountyDictionaryEntry', { inverse: null }),
        plotNumber: _emberData['default'].attr('string')
    });
    // user model
    //Address = Ember.Object.extend({
    //    street: null,
    //    streetNo: null,
    //    aptNo: null,
    //    postalCode: null,
    //    city: null,
    //    district: null,
    //});

    exports['default'] = Address;
});